<template>
  <PageContainer>
    <PageGrid>
      <PageGridColumn>
        <PageHeading :title="t('page.title')" />
        <CatalogCities :items-per-page="60" />
      </PageGridColumn>
    </PageGrid>
  </PageContainer>
</template>

<script lang="ts" setup>
const { t } = useI18n()
const { $gtm } = useNuxtApp()

usePageMetas({
  title: t('page.title'),
  description: t('page.description'),
})

onMounted(() => {
  $gtm.trackPageView()
})

defineOptions({
  name: 'GenericPageCities',
})
</script>

<i18n>
de:
  page:
    title: "Städte"
    description: "Wählen Sie Ihre Stadt aus, um die für Sie passenden Kino Vorstellungen zu sehen. Buchen Sie anschließend bequem online und erhalten Sie die Tickets sofort per E-Mail."
es:
  page:
    title: "Ciudades"
    description: "Selecciona tu ciudad para ver las proyecciones de cine que te interesan. Reserva cómodamente en línea y recibe tus entradas por correo electrónico."
</i18n>
