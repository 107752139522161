<template>
  <div class="contents">
    <CmsPage v-if="page" :page />
    <GenericPageHomepage v-else />
  </div>
</template>

<script lang="ts" setup>
const { page } = useCms()

defineI18nRoute({
  paths: {
    en: '/',
    de: '/',
    es: '/',
  },
})

defineOptions({
  name: 'PageHomepage',
})
</script>
