<template>
  <ShowLink
    :show
    class="flex min-h-16 break-inside-avoid items-center space-x-4 rounded-lg p-2"
  >
    <div class="h-10 w-10 shrink-0 rounded-full p-2">
      <InlineSvg
        :name="SVG_ICON.TICKET_CONFIRMATION_OUTLINE"
        class="h-8 w-8 -rotate-45 fill-current"
      />
    </div>

    <div class="text-pretty text-sm">
      <div class="flex flex-wrap">
        <div class="mr-2 text-base font-bold" v-text="show.name" />
        <Badge v-for="{ name } in flags" :key="name" class="mr-1 text-xs">
          {{ name }}
        </Badge>
      </div>
      <div v-text="show.cinema?.name" />
      <div v-text="showDate" />
    </div>
  </ShowLink>
</template>

<script lang="ts" setup>
import type { Show, Flag } from '#gql/default'

interface Props {
  show: Show & { flags: (Flag & { isHidden?: boolean })[] }
}

const props = defineProps<Props>()

const { d } = useI18n()

const showDate = computed(
  () =>
    `${d(props.show.beginning, {
      weekday: 'short',
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
    })} - ${d(props.show.beginning, {
      hour: 'numeric',
      minute: 'numeric',
    })}`
)

const flags = computed(() =>
  props.show.flags.filter(({ isHidden = false }) => !isHidden)
)

defineOptions({
  name: 'SearchPageResultShow',
})
</script>
