<template>
  <PageContainer
    :default-horizontal-spacing="false"
    :default-vertical-spacing="false"
  >
    <PageGrid>
      <PageGridColumn>
        <CatalogMovieList
          :layout="MOVIE_LIST_LAYOUT.CAROUSEL"
          :autoplay="false"
          :movie-list-id="config.movieListIds.HIGHLIGHTS"
          :render-above-the-fold="true"
        />
      </PageGridColumn>
    </PageGrid>
  </PageContainer>
  <PageContainer>
    <PageGrid
      :gap="{
        default: PAGE_GRID_GAP_SIZE.LG,
        sm: PAGE_GRID_GAP_SIZE.LG,
        md: PAGE_GRID_GAP_SIZE.LG,
        lg: PAGE_GRID_GAP_SIZE.LG,
      }"
    >
      <PageGridColumn>
        <PageSectionHeading
          :title="t('page.section.nowPlaying')"
          class="mb-4"
        />
        <CatalogMovieList
          :layout="MOVIE_LIST_LAYOUT.HORIZONTAL"
          :movie-list-id="config.movieListIds.NOW_PLAYING"
        />
      </PageGridColumn>
      <PageGridColumn>
        <PageSectionHeading
          :title="t('page.section.comingSoon')"
          class="mb-4"
        />
        <CatalogMovieList
          :layout="MOVIE_LIST_LAYOUT.HORIZONTAL"
          :movie-list-id="config.movieListIds.COMING_SOON"
        />
      </PageGridColumn>
    </PageGrid>
  </PageContainer>
</template>

<script lang="ts" setup>
const { t } = useI18n()
const { $gtm } = useNuxtApp()

const config = useAppConfig()

usePageMetas({
  title: t('page.title'),
  description: t('page.description'),
})

onMounted(() => {
  $gtm.trackPageView()
})

defineOptions({
  name: 'GenericPageHomepage',
})
</script>

<i18n>
de:
  page:
    title: "Kinotickets online kaufen"
    description: "Kinotickets für alle Städte &amp; alle Filme schnell und bequem online &amp; mobil kaufen – Große Auswahl an Kinos bei kinoheld mit E-ticket."
    section:
      highlights: "Highlights"
      nowPlaying: "Jetzt im Kino"
      comingSoon: "Demnächst im Kino"
es:
  page:
    title: "Compra entradas de cine online"
    description: "Compre entradas de cine para todas las ciudades y todas las películas de forma rápida y cómoda en línea y móvil: gran selección de cines en entradas con E-ticket."
    section:
      highlights: "Destacados"
      nowPlaying: "Ahora en el cine"
      comingSoon: "Próximamente en el cine"
</i18n>
