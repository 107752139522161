import type { FetchCitiesQueryVariables, City } from '#gql/default'

export default async function useCities2({
  fetchOperation,
  urlPrefix,
  first = 100,
}: {
  fetchOperation: 'FetchCities'
  urlPrefix?: string
  preselected: FetchCitiesQueryVariables
  first?: number
}) {
  const { fetchParams } = useFetchParams({
    urlPrefix,
  })

  const variables: Ref<FetchCitiesQueryVariables> = ref(getVariables(false, 1))
  const { data, status } = await useAsyncGql(fetchOperation, variables)

  const { results, pending, paginatorInfo, next, prev } = useFetchResults({
    data,
    status,
    fetchFallbackResults: false,
    fetchParams,
    variables,
    getVariables,
  })

  function getVariables(_: any, page: number) {
    const { city, search } = fetchParams.value
    const proximity = city ? { city, distance: 25 } : undefined

    return {
      proximity,
      search,
      first,
      page,
    }
  }

  const cities = computed(() => results.value as City[])

  return {
    cities,
    pending,
    paginatorInfo,
    next,
    prev,
  }
}
