import type {
  Movie,
  Show,
  Cinema,
  City,
  FetchSearchForSearchPageQueryVariables,
  FetchSearchForAutoSuggestQueryVariables,
} from '#gql/default'

export default async function useSearch2({
  operation,
  variables,
}: {
  operation: 'FetchSearchForSearchPage' | 'FetchSearchForAutoSuggest'
  variables: Ref<
    | FetchSearchForSearchPageQueryVariables
    | FetchSearchForAutoSuggestQueryVariables
  >
}) {
  const { data, status } = await useAsyncGql({ operation, variables })
  const results = computed(() => data.value?.search ?? [])
  const movies = computed(
    () =>
      results.value
        .filter((result) => result.__typename === 'Movie')
        .map(({ __typename, ...movie }) => movie) as Movie[]
  )
  const cinemas = computed(
    () =>
      results.value
        .filter((result) => result.__typename === 'Cinema')
        .map(({ __typename, ...cinema }) => cinema) as Cinema[]
  )
  const cities = computed(
    () =>
      results.value
        .filter((result) => result.__typename === 'City')
        .map(({ __typename, ...city }) => city) as City[]
  )
  const shows = computed(
    () =>
      results.value
        .filter((result) => result.__typename === 'Show')
        .map(({ __typename, ...show }) => show) as Show[]
  )

  const noResults = computed(() => !results.value.length)
  const pending = computed(() => status.value === 'pending')

  return {
    noResults,
    movies,
    cinemas,
    cities,
    shows,
    pending,
  }
}
