<template>
  <PageSectionHeading :title="t('cinemas')" class="mb-4" />
  <CinemaCardsHorizontal
    v-if="layout === CINEMA_CARDS_LAYOUT.HORIZONTAL"
    :cinemas
  />
  <CinemaCardsVertical v-else-if="CINEMA_CARDS_LAYOUT.VERTICAL" :cinemas />
</template>

<script lang="ts" setup>
import type { Cinema } from '#gql/default'

interface Props {
  cinemas: Cinema[]
  layout: CinemaCardsLayout
}

const { t } = useI18n()

defineProps<Props>()

defineOptions({
  name: 'SearchPageResultCinemas',
})
</script>

<i18n>
de:
  cinemas: "Kinos"
es:
  cinemas: "Cines"
</i18n>
