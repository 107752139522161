<template>
  <div class="contents">
    <CmsPage v-if="page" :page />
    <GenericPageVouchers v-else />
  </div>
</template>

<script lang="ts" setup>
const { page } = useCms()

defineI18nRoute({
  paths: {
    en: '/vouchers',
    de: '/gutscheine',
    es: '/codigos',
  },
})

defineOptions({
  name: 'PageVouchers',
})
</script>
