<template>
  <div
    class="scrollbar-none group-data-[default-horizontal-spacing=true]:container-bleed mt-6 overflow-x-auto group-data-[default-horizontal-spacing=true]:pl-5 sm:contents"
  >
    <ul class="flex sm:flex-wrap">
      <li>
        <FiltersSearch />
      </li>
      <li>
        <FiltersCity
          :use-geo-location="false"
          :reset-filters="[URL_FILTER.TYPES]"
        />
      </li>
      <li>
        <FiltersGeneric :filter-option="typesFilters" />
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import type { FilterOption } from '#gql/default'

const { t } = useI18n()

const typesFilters = {
  key: URL_FILTER.TYPES,
  label: t('label'),
  values: [
    { key: SEARCH_TYPE_ENUM.MOVIE, label: t('btn.movies') },
    { key: SEARCH_TYPE_ENUM.CINEMA, label: t('btn.cinemas') },
    { key: SEARCH_TYPE_ENUM.CITY, label: t('btn.cities') },
    { key: SEARCH_TYPE_ENUM.SHOW, label: t('btn.shows') },
  ],
} as FilterOption

defineOptions({
  name: 'SearchPageFilters',
})
</script>

<i18n>
de:
  label: "Ergebnisse filtern"
  btn:
    movies: "Filme"
    cinemas: "Kinos"
    cities: "Städte"
    shows: "Vorstellungen"
es:
  label: "Filtrar resultados"
  btn:
    movies: "Peliculas"
    cinemas: "Cines"
    cities: "Ciudades"
    shows: "Programas"
</i18n>
