<template>
  <NuxtLink
    :to="detailLink"
    class="text-neutral-dark-2 text-dark dark:text-light flex flex-col text-center text-sm font-light hover:underline"
  >
    <PersonProfileImage
      :person="person"
      :image-config="imageConfig"
      :rounded="true"
      data-image
    />
    <div class="break-words pt-3 leading-tight" v-text="person.name" />
  </NuxtLink>
</template>

<script setup lang="ts">
import type { Person } from '#gql/defaults'

interface Props {
  person: Person
  renderAboveTheFold?: boolean
  imageConfig?: ImageConfig
}

const localeRoute = useLocaleRoute()

const props = withDefaults(defineProps<Props>(), {
  renderAboveTheFold: false,
  imageConfig: undefined,
})

const detailLink = computed(() => {
  return localeRoute({
    name: ROUTE_NAMES.PERSON_PAGE,
    params: {
      personSlug: props.person.urlSlug,
    },
  })
})

defineOptions({
  name: 'PersonCard',
})
</script>
