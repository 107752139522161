<template>
  <div class="contents">
    <CmsPage v-if="page" :page />
    <GenericPagePersons v-else />
  </div>
</template>

<script lang="ts" setup>
const { page } = useCms()

defineI18nRoute({
  paths: {
    en: '/persons',
    de: '/personen',
    es: '/personas',
  },
})

defineOptions({
  name: 'PagePersons',
})
</script>
