import type { FetchPersonsQueryVariables, Person } from '#gql/default'

export default async function usePersons({
  fetchOperation,
  urlPrefix,
  first = 100,
}: {
  fetchOperation: 'FetchPersons'
  urlPrefix?: string
  preselected: FetchPersonsQueryVariables
  first?: number
}) {
  const route = useRoute()

  const { fetchParams } = useFetchParams({
    urlPrefix,
  })

  const page = computed(() => {
    return route?.query?.page ? Number(route.query.page) : 1
  })

  const variables: Ref<FetchPersonsQueryVariables> = ref(
    getVariables(false, page.value)
  )
  const { data, status } = await useAsyncGql(fetchOperation, variables)

  const { results, pending, paginatorInfo, next, prev } = useFetchResults({
    data,
    status,
    fetchFallbackResults: false,
    fetchParams,
    variables,
    getVariables,
  })

  function getVariables(_: any, page: number) {
    const { search } = fetchParams.value

    return {
      search,
      first,
      page,
    }
  }

  const persons = computed(() => results.value as Person[])

  return {
    persons,
    pending,
    paginatorInfo,
    next,
    prev,
  }
}
