<template>
  <PageSectionHeading :title="t('cities')" class="mb-4" />
  <div class="gap-4 sm:columns-2">
    <SearchPageResultCity
      v-for="city in cities"
      :key="city.urlSlug"
      :city
      class="dark:even:bg-dark even:bg-light dark:bg-neutral-dark-1 bg-neutral-light-1"
    />
  </div>
</template>

<script lang="ts" setup>
import type { City } from '#gql/default'

interface Props {
  cities: City[]
}

const { t } = useI18n()

defineProps<Props>()

defineOptions({
  name: 'SearchPageResultCities',
})
</script>

<i18n>
de:
  cities: "Städte"
es:
  cities: "Ciudades"
</i18n>
