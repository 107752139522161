<template>
  <PageSectionHeading :title="t('movies')" class="mb-4" />
  <MovieCardsHorizontal
    v-if="layout === MOVIE_CARDS_LAYOUT.HORIZONTAL"
    :movies
  />
  <MovieCardsVertical v-else-if="layout === MOVIES_LAYOUT.VERTICAL" :movies />
</template>

<script lang="ts" setup>
import type { Movie } from '#gql/default'

interface Props {
  movies: Movie[]
  layout: MovieCardsLayout
}

const { t } = useI18n()

defineProps<Props>()

defineOptions({
  name: 'SearchPageResultMovies',
})
</script>

<i18n>
de:
  movies: "Filme"
es:
  movies: "Peliculas"
</i18n>
