<template>
  <div data-catalog-persons class="space-y-6">
    <div
      class="scrollbar-none group-data-[default-spacing=true]:container-bleed overflow-x-auto group-data-[default-spacing=true]:pl-5 sm:contents"
    >
      <ul class="flex sm:flex-wrap">
        <li>
          <FiltersSearch :placeholder="t('searchPlaceholder')" :url-prefix />
        </li>
      </ul>
    </div>

    <PersonCardsVertical
      :persons
      :paginator-info
      :url-prefix
      :items-per-page
      :next
      :prev
    />
  </div>
</template>

<script lang="ts" setup>
interface Props {
  urlPrefix?: string
  itemsPerPage?: number
}

const { urlPrefix, itemsPerPage } = defineProps<Props>()
const { t } = useI18n()

const { persons, paginatorInfo, next, prev } = await usePersons({
  fetchOperation: 'FetchPersons',
  urlPrefix,
  preselected: {},
  first: itemsPerPage,
})

defineOptions({
  name: 'CatalogPersons',
})
</script>

<i18n>
de:
  searchPlaceholder: "Person suchen"
es:
  searchPlaceholder: "Buscar persona"
</i18n>
