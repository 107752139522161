<template>
  <PageContainer>
    <PageGrid>
      <PageGridColumn>
        <PageHeading :title="t('page.title')" />
        <CatalogCinemas
          :filters="[URL_FILTER.SEARCH, URL_FILTER.CITY, URL_FILTER.TYPES]"
          :layout="CINEMA_CARDS_LAYOUT.VERTICAL"
          :render-above-the-fold="true"
          :items-per-page="20"
          :link-params="[]"
          :fetch-fallback-results="true"
        />
      </PageGridColumn>
    </PageGrid>
  </PageContainer>
</template>

<script lang="ts" setup>
const { t } = useI18n()
const { $gtm } = useNuxtApp()

usePageMetas({
  title: t('page.title'),
  description: t('page.description'),
})

onMounted(() => {
  $gtm.trackPageView()
})

defineOptions({
  name: 'GenericPageCinemas',
})
</script>

<i18n>
de:
  page:
    title: "Kinos"
    description: "Jetzt bei kinoheld Kinotickets schnell und bequem online kaufen."
es:
  page:
    title: "Cines"
    description: "Compra tus entradas de cine de forma rápida y cómoda en línea con entradas."
</i18n>
