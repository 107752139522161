<template>
  <PageSectionHeading :title="t('shows')" class="mb-4" />
  <div class="gap-4 sm:columns-2">
    <SearchPageResultShow
      v-for="show in visibleShows"
      :key="show.id"
      :show
      class="dark:even:bg-dark dark:even:hover:bg-neutral-dark-2 even:bg-light dark:odd:bg-neutral-dark-2 dark:odd:hover:bg-neutral-dark-1 bg-neutral-light-1 my-2 first:mt-0 last:mb-0"
    />
  </div>

  <FetchMoreButton
    v-if="isMinimized"
    :pending="false"
    @click="isMinimized = false"
  />
</template>

<script lang="ts" setup>
import type { Show } from '#gql/default'

interface Props {
  shows: Show[]
  limit: number
}

const { t } = useI18n()
const props = defineProps<Props>()

const isMinimized = ref(props.limit && props.shows.length > props.limit)
const visibleShows = computed(() =>
  isMinimized.value ? props.shows.slice(0, props.limit) : props.shows
)

defineOptions({
  name: 'SearchPageResultShows',
})
</script>

<i18n>
de:
  shows: "Vorstellungen"
es:
  shows: "Programas"
</i18n>
