<template>
  <NuxtLink :to="detailLink">
    <slot />
  </NuxtLink>
</template>

<script lang="ts" setup>
import type { City } from '#gql/default'

interface Props {
  city: City
}

const props = defineProps<Props>()
const localeRoute = useLocaleRoute()

const detailLink = computed(() =>
  localeRoute({
    name: ROUTE_NAMES.CITY_PAGE,
    params: {
      citySlug: props.city.urlSlug,
    },
  })
)

defineOptions({
  name: 'CityLink',
})
</script>
