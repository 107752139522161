import type { City } from '#gql/default'

export default function formatPostcodes(city: City) {
  return [
    city.postcodes?.[0]?.postcode,
    city.postcodes?.[city.postcodes.length - 1]?.postcode,
  ]
    .filter(Boolean)
    .join(' - ')
}
