<template>
  <ImageWrapper
    :image="image"
    :config="getImageConfig()"
    :classes="{
      placeholder: {
        'w-full h-full absolute top-0': true,
        'rounded-lg': rounded,
      },
      image: {
        'w-full h-auto absolute top-0': true,
        'rounded-lg': rounded,
      },
      container: 'aspect-[2/3] relative',
    }"
  >
    <template #default="{ classList }">
      <ImagePlaceholder
        :icon="SVG_ICON.ACCOUNT_CIRCLE_OUTLINE"
        :class="[...classList, '!border-0', { 'rounded-lg': rounded }]"
      />
    </template>
  </ImageWrapper>
</template>

<script lang="ts" setup>
import type { Person } from '#gql/default'

interface Props {
  person: Person
  renderAboveTheFold?: boolean
  rounded?: boolean
  imageConfig?: ImageConfig
}

const props = withDefaults(defineProps<Props>(), {
  renderAboveTheFold: false,
  rounded: false,
  imageConfig: undefined,
})

const image = getImage(props.person, 'profileImage', props.person.name)

function getImageConfig() {
  if (props.imageConfig) {
    return props.imageConfig
  }

  const appConfig = useAppConfig()
  return {
    ...appConfig.images.person.card,
    loading: props.renderAboveTheFold
      ? IMAGE_LOADING.EAGER
      : IMAGE_LOADING.LAZY,
  }
}

defineOptions({
  name: 'PersonProfileImage',
})
</script>
