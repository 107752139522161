<template>
  <div class="contents">
    <CmsPage v-if="page" :page />
    <GenericPageCities v-else />
  </div>
</template>

<script lang="ts" setup>
const { page } = useCms()

defineI18nRoute({
  paths: {
    en: '/cities',
    de: '/staedte',
    es: '/ciudades',
  },
})

defineOptions({
  name: 'PageCities',
})
</script>
