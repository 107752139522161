<template>
  <PageContainer>
    <PageGrid>
      <PageGridColumn>
        <PageHeading :title="t('page.title')" />
        <CatalogPersons :items-per-page="20" />
      </PageGridColumn>
    </PageGrid>
  </PageContainer>
</template>

<script lang="ts" setup>
const { t } = useI18n()
const { $gtm } = useNuxtApp()

usePageMetas({
  title: t('page.title'),
  description: t('page.description'),
})

onMounted(() => {
  $gtm.trackPageView()
})

defineOptions({
  name: 'GenericPagePersons',
})
</script>

<i18n>
de:
  page:
    title: "Cast & Crew"
    description: "Durchsuchen Sie Schauspieler, Schauspielerinnen, Produzenten und Besetzungen aktueller und beliebter Filme"
es:
  page:
    title: "Reparto y equipo"
    description: "Busca actores, actrices, productores y repartos de películas actuales y populares"
</i18n>
